import { Controller } from "@hotwired/stimulus"

// If need to add some custom block of html to ignore list -> add class '.slide-menu--ignore-list-item' to block
export default class extends Controller {
  static values = {
    ignoreList: String
  }

  connect() {
    this.boundOnResize = this.onResize.bind(this)
    window.addEventListener('resize', this.boundOnResize)
  }

  // #btnLeftMenu (adds .collapsed-menu) conflicts with toggle-menu-button (adds .open to .slide-menu) at widths <= 1200
  // fix this by listening to window size change and hiding #btnLeftMenu if width drops below 1201
  onResize() {
    if (window.innerWidth <= 1200) {
      $('body').removeClass('collapsed-menu');
      setCookie('menu_collapsed', '', -1); // delete cookie
      $('body').removeClass('show-organizations-menu')
    }
  }

  toggleOpen() {
    const isOpen = this.element.classList.toggle('open')
    if (isOpen) {
      this.boundHandleHide = this.handleHide.bind(this)
      window.addEventListener('click', this.boundHandleHide)
    }
  }

  disconnect() {
    window.removeEventListener('click', this.boundHandleHide)
    window.removeEventListener('resize', this.boundOnResize)
  }

  handleHide(e) {
    if (window.innerWidth <= 1200 & this.element.querySelector('.menu-left').classList.contains('is-editing'))
      return;

    // prevent click for some classes where window width less then 720px
    const ignoreList = this.ignoreListValue.split(',').filter(Boolean).concat([
      '.column-list > .column-header.header-primary',
      '.column-list > .column-sub-header',
      '.messages-column-left .column-header.header-primary',
      '.messages-column.messages-column-contacts .column-sub-header',
      '.column-settings-menu .sub-menu-group--title'
    ])
    if (ignoreList.some(id => e.target.closest(id)) && window.innerWidth <= 720) return;
    if (!this.element.contains(e.target)) {
      this.element.classList.remove('open')
      $('body').removeClass('show-organizations-menu')
      window.removeEventListener('click', this.boundHandleHide)
    }
  }
}